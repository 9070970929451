<template>
  <div class="QueryRegion">
    <div class="QueryRegion_h">
      <div class="QueryRegion_bac"></div>
      <div class="QueryRegion_til">
        <img src="@/assets/img/query/query_til.png" alt="" />
      </div>
      <div class="QueryRegion_box">
        <div class="QueryRegion_content">
          <van-field
                  :value="valueCer"
                  label="查询证书"
                  placeholder="请选择"
                  readonly
                  clickable
                  is-link
                  name="picker"
                  :border="false"
                  input-align="right"
                  @click="showPicker = true"
          />
        </div>
      </div>
      <van-button class="QueryRegion_btn" type="info" round @click="queryHandler">查询</van-button>
    </div>
    <!--选择地区-->
    <van-popup class="van-popup-custom" v-model="showPicker" position="bottom" round :style="{ height: '80%' }">
      <div class="popup-header">
        <div class="popup-title">
          <span class="popup-title-text">请选择证书</span>
          <van-icon name="cross" class="popup-header-close" @click="showPicker = false" />
        </div>
        <van-search v-model="keywords" placeholder="请输入证书名称搜索" maxlength="30" />
      </div>
      <div class="popup-content">
        <template v-if="!keywords">
          <van-index-bar :index-list="[]" :sticky="false">
            <template v-for="item in typeList">
              <van-index-anchor :index="item.name" />
              <van-cell v-for="(cerItem, cerIndex) in item.list" :title="cerItem" @click="onConfirm(cerItem)" :border="cerIndex != item.list.length - 1" />
            </template>
          </van-index-bar>
        </template>
        <template v-else-if="listFilter.length > 0">
          <van-cell v-for="(cerItem, cerIndex) in listFilter" :title="cerItem" @click="onConfirm(cerItem)" :border="cerIndex != listFilter.length - 1" />
        </template>
        <template v-else>
          <div class="empty">没有找到您搜索的证书名称</div>
        </template>
      </div>
    </van-popup>
    <copyrightIp></copyrightIp>
    <!-- 查询超限提示 -->
    <PopupTips :show.sync="showLimitQuery">
      <p>
        您今日或本月查询次数超限，如需相关服务，请咨询公司的<span style="color: #1789f9">客服专员</span>或在公众号<span style="color: #1789f9">回复【合作】</span>留言
      </p>
    </PopupTips>
  </div>
</template>

<script>
  import { getCityCertificate, getSalaryResult, postLog } from "@/api/salaryQuery";
  import { validatenull } from "@/utils/validate";
  import { Notify, Field, Picker, Popup, Button, IndexBar,IndexAnchor, Cell, Search, Icon } from "vant";
  import copyrightIp from "@/components/copyrightIp";
  import PopupTips from "./components/PopupTips.vue";
  import resultData2Content from './js/resultData2Content'
  
  export default {
    name: 'QueryCertificate',
    components: {
      [Notify.name]: Notify,
      [Field.name]: Field,
      [Picker.name]: Picker,
      [Popup.name]: Popup,
      [Button.name]: Button,
      [IndexBar.name]: IndexBar,
      [IndexAnchor.name]: IndexAnchor,
      [Cell.name]: Cell,
      [Search.name]: Search,
      [Icon.name]: Icon,
      copyrightIp,
      PopupTips,
    },
    data() {
      return {
        keywords: '',
        valueCer: "",
        indexList: [],
        list: [],
        showPicker: false,
        showLimitQuery: false,
        typeList: [
          { name: '技能人员职业资格', list: [] },
          { name: '专业技术人员职业资格', list: [] }
        ]
      };
    },
    beforeCreate() {
      document.title = '证书查询';
    },
    created() {
      this.fetchCertificateList()
    },
    methods: {
      // 确认选择地区
      onConfirm(value) {
        this.showPicker = false;
        this.valueCer = value;
      },
      // 获取证书列表
      fetchCertificateList() {
        getCityCertificate().then((res) => {
          if (res.data.code == 0) {
            const { skilledList, professionalList } = res.data.data;
            this.typeList[0].list = skilledList;
            this.typeList[1].list = professionalList;
          } else {
            Notify("获取证书列表失败");
          }
        });
      },
      // 查询
      async queryHandler() {
        if (!this.valueCer) {
          return Notify("请选择查询证书");
        }
        
        try {
          const { queryCodeId, queryType } = this.$route.params;
          const res = await getSalaryResult({
            userId: localStorage.getItem("querSalaryUserId"),
            queryFunction: queryCodeId,
            scope: this.valueCer,
            year: new Date().getFullYear(),
          });
          
          if (res.data.code == 0) {
            // const resData = queryType == "dimissionAllowance" ? { dimissionAllowanceList: res.data.data } : res.data.data;
            const resData = res.data.data;
            const result = {
              ...resData,
              city: this.valueCer,
              year: new Date().getFullYear(),
            };
            this.querySuccess(result);
            const resultData = resultData2Content(resData, queryType);
            const logValue = validatenull(resultData) ? this.valueCer : resultData;
            this.postLog('查询成功: '+ logValue);
          } else {
            res.data.code == 1 ? this.showLimitQuery = true : Notify(res.data.msg)
            this.postLog('查询失败: '+res.data.msg)
          }
        } catch (error) {
          this.postLog('查询失败: 未知错误');
        }
      },
      // 跳转到查询结果页
      querySuccess(result) {
        sessionStorage.setItem("querSalaryResult", JSON.stringify(result));
        sessionStorage.setItem("isShare", false);
        const { queryCodeId, queryType } = this.$route.params;
        this.$router.push({
          path: `/salaryQueryv1/result/${queryCodeId}/${queryType}/${this.valueCer}`,
        });
      },
      // 埋点
      postLog(res) {
        postLog({
          userId: localStorage.getItem("querSalaryUserId"),
          clickPlace: "查询-继续教育证书",
          queryResult: res
        });
      }
    },
    computed: {
      listFilter() {
        const keywords = this.keywords;
        const listOri = this.typeList;
        let listFilter = [];
        listOri.forEach(item => {
          item.list.forEach(cerItem => {
            if (cerItem.includes(keywords)) {
              listFilter = [...listFilter, cerItem];
            }
          })
        })
        return listFilter;
      }
    }
  };
</script>

<style lang="scss" scoped>
  .QueryRegion {
    width: 100%;
    height: 100%;
    position: relative;
    .QueryRegion_h {
      min-height: calc(100% - 100px);
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
      border: 1px solid rgba(0,0,0,0);
    }
    .QueryRegion_bac {
      width: 100%;
      height: 130px;
      background-image: url("../../assets/img/query/bg2.png");
      background-size: 100%;
      background-repeat: no-repeat;
      background-position-y: 100%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;
    }
    .QueryRegion_til {
      width: 300px;
      padding: 32px 0;
      margin: auto;
      img {
        text-align: center;
        width: 100%;
      }
    }
    .QueryRegion_box {
      width: 100%;
      padding: 0 10px;
      .QueryRegion_content {
        width: 100%;
        height: 50px;
        background: #fff;
        border-radius: 10px;
        overflow: hidden;
        display: flex;
        align-items: center;
      }
    }
    .QueryRegion_btn {
      display: block;
      margin: 50px auto;
      width: 350px;
      height: 50px;
      background: linear-gradient(90deg, #4881f5, #5bacfc);
      border-radius: 25px;
      font-size: 16px;
      font-weight: 500;
    }
    .van-popup-custom {
      .popup-header {
        /*background-color: #52B71C;*/
        padding: 0 15px;
        height: 100px;
        .popup-title {
          height: 55px;
          line-height: 55px;
          text-align: center;
          position: relative;
          .popup-title-text {
            font-size: 16px;
          }
          .popup-header-close {
            font-size: 20px;
            padding: 5px;
            position: absolute;
            right: 0;
            top: 50%;
            -webkit-transform: translateY(-50%);
            -moz-transform: translateY(-50%);
            -ms-transform: translateY(-50%);
            -o-transform: translateY(-50%);
            transform: translateY(-50%);
          }
        }
        ::v-deep .van-search {
          padding: 0;
          &:focus {
            border: 1px solid #409CF5;
          }
          .van-cell {
            line-height: 32px;
          }
          .van-search__content {
            -webkit-border-radius: 5px;
            -moz-border-radius: 5px;
            border-radius: 5px;
          }
        }
      }
      .popup-content {
        padding: 5px 15px 0 15px;
        height: calc(100% - 110px);
        overflow: auto;
        ::v-deep .van-cell {
          padding: 8px 10px;
          font-size: 12px;
          &::after {
            left: 10px;
            right: 10px;
          }
        }
        ::v-deep .van-index-anchor {
          margin-top: 6px;
          margin-bottom: 4px;
          padding: 4px 10px;
          background-color: #f4f4f5;
          font-size: 14px;
          -webkit-border-radius: 5px;
          -moz-border-radius: 5px;
          border-radius: 5px;
        }
        .empty {
          color: #999999;
          font-size: 12px;
          margin-top: 15px;
          text-align: center;
        }
      }
    }
  }
</style>